$global-font-family: "Open Sans", sans-serif;
$header-font-family: "Open Sans", sans-serif;

@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials


:root {
 --bg: #fff;
 --bg2: #fafafa;
 --bg3: #fcfcfc;
 --text-color: #555;
 --green1: #008f68;
 --green2: #6DB65B;
 --green3: #4AAE9B;
 --yellow1: #EFBB35;
 --yellow2: #dfa612;
 --yellow3: #FAE042;
 --very-subtle: rgba(0, 0, 0, .05);
 --top1: rgba(107, 183, 86, .95);
 --top2: rgba(0, 143, 104, .95);
 --subtle-green3: #c9e9c066;
 --subtle-yellow1: rgba(239, 187, 53, 0.6);
 --subtle-yellow2: rgba(236, 198, 48, .1);
 --subtle-yellow3: rgba(236, 198, 48, .3);
 --scrolly: rgba(114, 186, 94, 0.55);
}
.dark {
	--bg: #222831;
	--bg2: rgba(0, 0, 0, .3);
	--bg3: #1A1D24;
	--text-color: #F9FFEE;
	--green1: #EFBB35;
	--green2: #E84279;
	--green3: #dfa612;
	--top1: #302E24;
	--top2: #28313E;
   --subtle-green3: rgba(236, 198, 48, .1);
	--subtle-yellow3: rgba(236, 198, 48, .1);
   --scrolly: rgba(236, 198, 48, 0.53);
}

@font-face {
 font-family:"IBM Plex Mono ExtraLight";
 src:url("https://static.astrob0t.com/_assets_/fonts/plex/ibmplexmono-extralightitalic-latin1.woff2") format("woff2"),url("https://static.astrob0t.com/_assets_/fonts/plex/ibmplexmono-extralightitalic-latin1.woff") format("woff");
 font-style:italic
}
@font-face {
 font-family:"IBM Plex Mono";
 src:url("https://static.astrob0t.com/_assets_/fonts/plex/ibmplexmono-text-latin1.woff2") format("woff2"),url("https://static.astrob0t.com/_assets_/fonts/plex/ibmplexmono-text-latin1.woff") format("woff");
 font-display:swap
}

/* remove underlining decoration of anchor texts*/
.page__content a, .page__content u {
   text-decoration: underline;
   text-decoration-color: var(--subtle-yellow3);
   -webkit-text-decoration-skip: ink;
   text-decoration-skip: ink;
}
.page__content a {
   transition: all .2s;
   color: var(--yellow2);
}
.page__content a:hover {
   color: var(--yellow1);
   text-decoration: underline;
   text-decoration-color: var(--subtle-yellow3);
   -webkit-text-decoration-skip: ink;
   text-decoration-skip: ink;
}
.page__content a:not(.btn):not(#goog-wm-sb):hover {
   text-decoration: underline;
   text-decoration-color: var(--subtle-yellow3);
   -webkit-text-decoration-skip: ink;
   text-decoration-skip: ink;
}
.toc__menu a {
   color: #898c8f;
}
.toc__menu a:hover {
   color: #494e52;
}

/* html paint*/
html {
   background: var(--bg);
   transition: background-color .25s ease-out;
}

/* body font*/
body {
   background: var(--bg);
	color: var(--text-color);
   font-weight: 300;
   transition: background-color .25s ease-out;
}

/* ensure the left sidebar sticky dowsn't have overflow Scrolling*/
.sidebar.sticky{
    height: auto;
    overflow: hidden;
}

/* Justify text everywhere except the Table of contents*/
.page__content {
  text-align: justify;
}
.sidebar__right {
  text-align: left;
}

/* Keyboard keys style*/
kbd {
padding:0.1em 0.6em;
border:1px solid #ccc;
font-size:.8em;
font-family:Arial,Helvetica,sans-serif;
background-color:#f7f7f7;
color:#333;
-moz-box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
-webkit-box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
-moz-border-radius:3px;
-webkit-border-radius:3px;
border-radius:3px;
display:inline-block;
margin:0 0.1em;
text-shadow:0 1px 0 #fff;
line-height:1.4;
white-space:nowrap;
}


/* the subscribe section decoration*/
.subs {
	position: relative;
	background: -webkit-linear-gradient(to bottom, transparent, var(--subtle-yellow2));
	background: -o-linear-gradient(to bottom, transparent, var(--subtle-yellow2));
   background: linear-gradient(to bottom, transparent, var(--subtle-yellow2));
	margin: 2em 0 0;
	padding: 3em 0;
   transform: skewY(6deg);
}
.subs .form {
	margin: 0 auto;
	width: 415px;
	/*padding: 2em 0 3em;*/
	text-align: center;
   transform: skewY(-6deg);
}
@media screen and (max-width:600px) {
	.subs .form {
		width: 80%;
      transform: skewY(-6deg);
	}
}
.subs .form .mc-field-group {
	position: relative
}
.subs .form h2 {
	color: var(--green1);
	line-height: 1.8;
	margin: 0;
	font-size: 1.5em
}
.subs .form p {
	color: var(--green1);
	margin-top: 0
}
.subs .form .email {
	border: none;
	outline: none;
	padding: 10px;
	width: 80%;
	color: rgba(0, 0, 0, .7);
	border-radius: 2px;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, .16), 0 0 0 1px rgba(0, 0, 0, .08);
	transition: .2s ease-in;
   text-align: center;
   border-radius: 8px;
}
.subs .form .email:focus {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 0 1px rgba(0, 0, 0, .08)
}
@media screen and (max-width:600px) {
	.subs .form .email {
		width: 90%
	}
}
.subs .form .heart {
	border: none;
	outline: none;
	background: 0 0;
	font-size: 1.3em;
	position: absolute;
	color: var(--green1);
	right: 11%;
	bottom: 0%;
   top: 5%;
	cursor: pointer;
	transition: .2s ease-in
}
.subs .form .heart:hover {
	color: var(--green3);
}
@media screen and (max-width:600px) {
	.subscribe .form .heart {
		font-size: 20px;
		bottom: 10%;
		right: 7%
	}
}
form {
    margin: 0 0 0 0;
    padding: 0;
    background-color: transparent;
}
input {
    padding: 0;
    margin-bottom: 0;
}

/*display or hide sidebar ads based on screen sizes*/
/* screen sizes below 1280px for responsive ads*/
@media screen and (max-width:1279px) {
   #ad160x600 {
      display: none;
   }
   #ad728x90 {
      display: none;
   }
   #ad300x600 {
      display: none;
      --display-ad: none;
   }
   #ad300x250 {
      display: none;
   }
   #ad_resp{
      display: block;
      --display-ad: block;
      text-align: center;
   }
}
/* screen sizes larger than 1280px*/
@media screen and (min-width:1280px) {
   #ad_resp {
      display: none;
      --display-ad: none;
   }
   #ad728x90 {
      display: none;
   }
   #ad160x600 {
      display: none;
   }
   #ad300x250 {
      display: none;
   }
   #ad300x600 {
      display: block;
      --display-ad: block;
      text-align: center;
   }
}
/* screen sizes between 1024px and 1279px*/
/*@media screen and (min-width:1024px) and (max-width:1279px) {
   #ad_resp {
      display: none;
   }
   #ad728x90 {
      display: none;
   }
   #ad300x600 {
      display: none;
   }
   #ad300x250 {
      display: none;
   }
   #ad160x600 {
      display: block;
      text-align: center;
   }
}*/
/* screen sizes between 754px and 1023px*/
/*@media screen and (min-width:754px) and (max-width:1023px) {
   #ad_resp {
      display: none;
   }
   #ad300x600 {
      display: none;
   }
   #ad160x600 {
      display: none;
   }
   #ad300x250 {
      display: none;
   }
   #ad728x90 {
      display: block;
      text-align: center;
   }
}*/
/* screen sizes smaller than 753px*/
/*@media screen and (max-width:753px) {
   #ad_resp {
      display: none;
   }
   #ad728x90 {
      display: none;
   }
   #ad160x600 {
      display: none;
   }
   #ad300x600 {
      display: none;
   }
   #ad300x250 {
      display: block;
      text-align: center;
   }
}*/

/* resize the boxes to fit the ads properly*/
@media screen and (min-width:1024px) and (max-width:1279px) {
   #main {
      max-width: 1366px;
   }
   .sidebar {
      width: calc(200px - 1em);
   }
   .page {
      width: calc(100% - 200px);
   }
   .page__related {
      width: calc(100% - 200px);
   }
}
@media screen and (min-width:1280px) {
   #main {
      max-width: 1366px;
   }
   .sidebar {
      width: calc(340px - 1em);
   }
   .page {
      width: calc(100% - 340px);
   }
   .page__related {
      width: calc(100% - 340px);
   }
}

/* tags and categories decoration*/
.page__taxonomy-item {
   border-radius: 20px;
   border: none;
   background: var(--green3);
}
.page__taxonomy-item:hover {
    border: none;
    background-color: var(--green2);
    color: #fff
}
.page__meta a, .comment__date a {
    color: #fff;
}

/* header decoration*/
header h1 {
   font-size: 3rem !important;
   text-align: center;
   font-style: italic;
   font-family: "IBM Plex Mono ExtraLight", "Segoe UI", "Helvetica Neue", Helvetica, Roboto, 'Open Sans', FreeSans, sans-serif;
   color: var(--green1);
}
.page__hero--overlay {
   background: linear-gradient(to right, var(--top1), var(--top2));
   margin-bottom: 1em;
   padding-bottom: 3.5em;
   padding-top: 1em;
   -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 65%, 50% 100%, 0 65%, 0 0);
   clip-path: polygon(50% 0%, 100% 0, 100% 65%, 50% 100%, 0 65%, 0 0);
}
.page__hero--overlay .wrapper {
   text-align: center;
}
.page__hero--overlay .page__lead {
   max-width: 1280px;
}
@media screen and (min-width:80em) {
   .page__hero--overlay .wrapper {
      text-align: center;
   }
}

/* masthead decoration*/
.masthead {
   border-bottom-width: 0px;
}
.masthead__inner-wrap {
    max-width: 100%;
    padding: 0px;
    background: linear-gradient(to right, var(--top1), var(--top2));
}
@media screen and (min-width:80em) {
   .masthead__inner-wrap {
       max-width: 100%;
       padding: 0px;
       background: linear-gradient(to right, var(--top1), var(--top2));
   }
}
.masthead__inner-wrap nav {
   height: 60px;
}
.greedy-nav::before {
    content: " ";
    display: table;
    margin-right: 1em;
}
.greedy-nav {
   background: linear-gradient(to right, var(--top1), var(--top2));
}
.navicon, .navicon::before, .navicon::after {
   background: #dde7ef;
}
.greedy-nav__toggle:hover .navicon, .greedy-nav__toggle:hover .navicon::before, .greedy-nav__toggle:hover .navicon::after {
    background: #dde7ef;
}
.greedy-nav__toggle {
   margin-bottom: 0px;
}
.greedy-nav a {
   color: #fff;
}
.greedy-nav a:hover {
   color: var(--yellow3);
}
.greedy-nav .hidden-links {
   background: var(--top2);
}
.greedy-nav .visible-links a::before {
   background: #bab973;
}
.greedy-nav .hidden-links a:hover {
    background: transparent;
    color: var(--yellow3);
}
.greedy-nav .hidden-links::after {
   border-color: var(--top2) transparent;
}
.search__toggle {
   margin: 0 1em;
   color: #ffffff;
}
.search__toggle:hover {
   color: var(--yellow3);
}

/* toc decoration*/
.toc .nav__title {
   background: var(--green1);
}

/* page pagination decoration*/
.pagination--pager:hover {
    background-color: var(--green1);
}

/* image decoration*/
img {
   display: block;
   margin-left: auto;
   margin-right: auto;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* archive page title and item decoration*/
.archive__subtitle {
   font-size: 2em;
}
.archive__item-excerpt {
   font-size: .9em;
   margin-bottom: 0;
}
.archive__item-title {
   font-size: 1.2em;
}
.archive__item-title a {
   color: var(--yellow2);
   text-decoration: none;
   font-weight: 300;
}
.archive__item-title a:hover {
   color: var(--yellow1);
   text-decoration: none;
}
@media screen and (max-width: 40em) {
   .archive__item-excerpt {
      font-size: 1.1em;
   }
   .archive__item-title {
      font-size: 1.6em;
   }
}
@media screen and (min-width: 40em) and (max-width: 75em) {
   .archive__item-excerpt {
      font-size: 1.0em;
   }
   .archive__item-title {
      font-size: 1.4em;
   }
}
.entries-list {
   column-count: 2;
   max-width: 650px;
   margin: 0 auto;
}
@media screen and (max-width: 40em) {
   .entries-list {
      column-count: 1;
      max-width: 650px;
      margin: 0 auto;
   }
}
.list__item {
   margin-bottom: 1rem;
   -webkit-column-break-inside: avoid;
   page-break-inside: avoid;
   break-inside: avoid;
   border: 2px solid var(--subtle-yellow1);
   border-radius: 14px;
   padding: 1rem;
   display: inline-block;
}

/* no underlining for inserted <ins> elements. removed underlining from google ads*/
ins {
  border-bottom: none;
}

/* page footer background transparent*/
.page__footer {
   background-color: transparent;
}

/* link decoration when clicked and focussed*/
a:focus, button:focus {
   outline: none;
}

/* front page decorations*/
.row {
    max-width: 45rem;
    margin: 0 auto;
    padding: 0 .9rem;
    position: relative;
}
div.front-flex {
 display:grid;
 grid-template-columns:1fr 1fr 1fr
}
@media only screen and (min-width: 30em) and (max-width: 64em) {
 div.front-flex {
  grid-template-columns:1fr 1fr
 }
}
@media only screen and (max-width: 30em) {
 div.front-flex {
  grid-template-columns:1fr
 }
}
@media only screen and (min-width: 70.063em) {
   .front-teaser:hover {
    transform:translateY(-2px)
   }
}
.front-teaser {
 margin:1em .7em;
 border-radius:3px;
 display:flex;
 align-items:center;
 justify-content:center;
 box-shadow:1px 1px 15px rgba(67,38,100,0.15);
 padding:1rem;
 background:#fcfcfc;
 text-decoration: none;
}
.dark .front-teaser {
   background: var(--bg3);
}
.front-teaser h3 {
 font-size:1em;
 color:#4AAE9B;
 text-align:center;
 font-weight:300;
 margin:0
}
@media only screen and (max-width: 40em) {
   .front-teaser {
    font-size:.6em
   }
   .front-teaser h3 {
    font-size:1.7em
   }
}
@media only screen and (max-width: 72em) {
 a.front-teaser {
  flex-basis:100% !important;
  margin:1.1em .7em
 }
}
a.top-teaser {
 background-color:var(--top2);
 box-shadow:1px 1px 15px 4px rgba(67,38,100,0.15)
}
.dark a.top-teaser {
    background-color: var(--bg3);
}
a.top-teaser h3 {
 color:white
}
hr {
    width: 15%;
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 4px solid #FAE042;
    margin: 2.5em auto 2.3em;
}

/* scroll progressbar on the top*/
.progress {
   background: linear-gradient(to right,var(--scrolly) var(--scroll),transparent 0);
   position: fixed;
   width: 100%;
   height: 8px;
   z-index: 3;
}

/* top doodle decoration*/
.wrap-doodle {
  position: relative;
  z-index: 0;
  height: 10em
}
.top-doodle {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0px;
}

/* more button in home page*/
.button, button.small, div a.button {
    background-image: linear-gradient(to bottom,#fff,#f8f8f8);
    border-radius: 8px;
    color: #4AAE9B;
    letter-spacing: 1px;
    padding: 10px 20px;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    font: inherit;
    text-decoration: none;
    transition: transform .15s cubic-bezier(0,1.8,1,1.8);
}
.button:hover, button.small:hover, div a.button:hover {
    transform: scale(1.05);
}
.button, button, label {
    cursor: pointer;
}
.button, button {
    margin: 0 0 1.25rem;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
}

/*external link icon*/
span.hu-external::after{
	color: #3b8dbd;
	font-size: .7em;
	font-weight: 600;
}
.ext_link::after {
    display: none;
    content: "\f35d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1em;
}
.fa-external-link-alt {
   height: .6em;
   bottom: .14em;
   position: relative;
}

/*selected text styling*/
::selection {
   color: #fff;
   background: #efbb35;
}

/* special highlight of affiliate links */
/* special highlight of amazon affiliate links */
a.amazon-aff-links {
	border-bottom: 1px dotted #EBB62C;
	text-decoration: none;
}
a.amazon-aff-links:hover {
	color: #EBB62C;
	border-bottom: 1px dashed #EBB62C;
	text-decoration: none !important;
}
a.amazon-aff-links:after{
   display: none;
   content: "\f270";
	color: #3b8dbd;
	font-family: "Font Awesome 5 Brands";
   font-size: 1em;
   left: .3em;
	position: relative;
   font-weight: 900;
	margin-right: .4em;
   speak: none;
   line-height: 1;
}
a.amazon-aff-links:hover::after {
    color: #EBB62C;
}
.fa-amazon {
    margin-left: .3em;
    margin-right: .4em;
}

/*the code snippets used in posts*/
.code-in-post {
	padding-top: 10px;
    padding-bottom: 10px;
}
.code-in-post pre{text-align: left;
clear: both;
padding-top: 10px;
padding-bottom: 10px;
margin: 0;
border: 1px dashed #2f6fab;
color: #000;
background-color: #f9f9f9;
line-height: 1.1em;
font-family: monospace;
max-width: 618px;
padding: 20px 10px 20px 10px;
/*overflow-x: auto;*/
overflow-y: auto;
white-space: pre;
background: none;
margin-bottom: 10px;
font-size: 14px !important;
}
.code-in-post pre::selection {
    background: #a7ccee;
    text-shadow: none;
    color: #000;
}
.code-in-post pre::-moz-selection  {
    background: #a7ccee;
    text-shadow: none;
    color: #000;
}

/* Site logo*/
.site-logo img {
   max-height: 100%;
   box-shadow: none;
   transform: translateY(.7em);
   transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
   height: 70px;
   width: 80px;
}
.site-logo img:hover {
    transform: translateY(1em) rotate(-2deg) scale(1.15);
    transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
}
@media screen and (max-width:1024px) {
   .site-logo img {
      max-height: 100%;
      box-shadow: none;
      transform: none;
      transition: none;
      height: 40px;
      width: 40px;
   }
   .site-logo img:hover {
       transform: none;
       transition: none;
   }
}
.bot-head.text-center {
   margin: 2em;
}
.bot-head.text-center img {
   width: 180px;
   height: 180px;
   box-shadow: none;
}
h1#page-title.page__title {
   font-size: 2rem;
   text-align: center;
   font-style: italic;
   line-height: 1.4em;
   font-family: "IBM Plex Mono ExtraLight", "Segoe UI", "Helvetica Neue", Helvetica, Roboto, 'Open Sans', FreeSans, sans-serif;
   }
@media screen and (max-width:40em){
   .bot-head.text-center {
      margin: .4em;
   }
   .bot-head.text-center img {
      width: 110px;
      height: 110px;
      box-shadow: none;
   }
   h1#page-title.page__title {
      font-size: 1.8em !important;
      letter-spacing: 0;
      padding: 0 .5em;
      margin-top: 0;
      text-align: center;
      font-style: italic;
      font-family: "IBM Plex Mono ExtraLight", "Segoe UI", "Helvetica Neue", Helvetica, Roboto, 'Open Sans', FreeSans, sans-serif;
   }
}
.bot-head img:hover {
    animation: colorrr 10s infinite;
}
@keyframes colorrr {
   0% {filter:hue-rotate(0deg)}
   50% {filter:hue-rotate(360deg)}
}

/* light dark toggle switch*/
.lightDarkSwitch {
   position: relative;
   width: 24px;
   height: 24px;
   margin: 0 1em;
   -webkit-user-select:none;
   -moz-user-select:none;
   -ms-user-select: none;
}
.lightDarkSwitch-checkbox {
   display: none;
}
.lightDarkSwitch-label {
   display: block;
   overflow: hidden;
   cursor: pointer;
   border: 2px solid #999999;
   border-radius: 20px;
   margin: 0;
   height: 24px;
}
.lightDarkSwitch-switch {
   display: block;
   width: 20px;
   height: 20px;
   background-color: var(--text-color);
   border-radius: 20px;
   position: absolute;
   top: 2px;
   bottom: 2px;
   right: 2px;
   -moz-transition: background-color .25s ease-out;
   -webkit-transition: background-color .25s ease-out;
   -o-transition: background-color .25s ease-out;
   transition: background-color .25s ease-out;
}
.lightDarkSwitch-checkbox:checked + .lightDarkSwitch-label {
   margin-left: 0;
}
.lightDarkSwitch-checkbox:checked + .lightDarkSwitch-label .lightDarkSwitch-switch {
   right: 2px;
   background-color: var(--text-color);
}
/* tooltip for the light-dark toggle*/
.modeTooltip {
  position: relative;
  display: inline-block;
}
.modeTooltip .modeTooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--text-color);
  color: var(--bg);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  margin-left: -97px;
  font-size: .7em;
  border: 1px solid var(--text-color);
}
.modeTooltip .modeTooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--text-color) transparent;
}
.modeTooltip:hover .modeTooltiptext {
  visibility: visible;
}

/* page content sub headings*/
.page__content h2 {
   border-bottom: none;
   display: flex;
   color: var(--yellow1);
}
.page__content h2::after {
   content: " ";
   background: linear-gradient(to right,var(--subtle-yellow1),var(--subtle-yellow2));
   height: 3px;
   position: relative;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: auto;
   margin: .75em 2px 0 3px;
   flex-grow: 1;
   border-radius: 2px;
}

/* search results*/
.search-content .search-input {
   text-align: center;
   color: var(--text-color);
}
.results__num {
   text-align: center;
}
.search__results {
   column-count: 3;
   max-width: 1200px;
   margin: 0 auto;
}
@media screen and (max-width: 40em) {
   .search__results {
      column-count: 1;
      max-width: 100%;
   }
}
@media screen and (min-width: 40em) and (max-width: 75em) {
   .search__results {
      column-count: 2;
      max-width: 100%;
   }
}
.search-content .archive__item {
   width: 100%;
   margin-bottom: 0;
}
.search__list__item {
   margin-bottom: 1rem;
   -webkit-column-break-inside: avoid;
   page-break-inside: avoid;
   break-inside: avoid;
   border: 2px solid var(--subtle-yellow1);
   border-radius: 14px;
   padding: 1rem;
   display: inline-block;
}

/* code decoration*/
p > code, a > code, li > code, figcaption > code, td > code {
   background-color: var(--subtle-yellow2);
   border-bottom: 2px solid var(--subtle-yellow3);
}

/* abbreviation decoration*/
abbr[title], abbr[data-original-title] {
   border-bottom-color: #EBB62C;
}

/* adsense ads visibility */
#adsense.visible {
   visibility: visible;
   opacity: 1;
   display: block;
   height: auto;
}
#ad300x600.visible,#ad_resp.visible {
   visibility: visible;
   opacity: 1;
   display: var(--display-ad);
   height: auto;
}
#adsense,#ad300x600,#ad_resp {
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0 2px 5px var(--very-subtle);
    background-color: var(--bg2);
    color: var(--text-color);
    font-size: var(--font-size);
    line-height: 1.3;
    margin: 0 auto;
    transition: all .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: none;
    height: 0;
    --font-size: 16px;
}
#adsense.visible::after,#ad300x600.visible::after,#ad_resp.visible::after {
    content: "ADS BY GOOGLE";
    display: block;
    padding: 10px var(--font-size);
    background: repeating-linear-gradient(45deg,transparent,transparent 25px,var(--subtle-yellow2) 25px,var(--subtle-yellow2) 50px);
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 0;
}

/* notice decoration*/
.notice--success::before {
    content: '👍';
    background: rgba(107,183,86,.95);
    border-radius: 50%;
    align-items: center;
    top: -.8rem;
    position: absolute;
    left: -.9rem;
    font-size: .8rem;
    text-align: center;
    height: 1.8em;
    width: 1.8em;
    display: inline-flex;
    justify-content: center;
}
.notice--success {
   border-left: 4px solid rgba(63,166,63,0.25);
   position: relative;
   text-align: center;
   padding: 1em 2em;
   color: rgba(107,183,86,.95);
}
.notice--danger::before {
    content: '!';
    color: white;
    background: red;
    align-items: flex-end;
    top: -1rem;
    font-weight: 700;
    font-size: .8rem;
    -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
    clip-path: polygon(50% 0,0 100%,100% 100%);
    position: absolute;
    left: -.9em;
    display: inline-flex;
    justify-content: center;
    height: 1.8em;
    width: 1.8em;
}
.notice--danger {
   border-left: 4px solid rgba(238,95,91,0.25);
   position: relative;
   padding: 1em 2em;
   text-align: center;
   color: rgb(183, 86, 86);
}

/* cookie consent*/
.CookieWarning.is-active {
    display: flex;
}
.CookieWarning {
    display: none;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
    position: fixed;
    box-shadow: 0 6px 20px 0 rgba(0,0,0,0.19),0 8px 17px 0 rgba(0,0,0,0.2);
    border-radius: 2px;
    left: 50%;
    margin-left: -200px;
    bottom: 1rem;
    background: var(--text-color);
    color: var(--bg);
    padding: 0.8rem;
    font-weight: 600;
    width: 400px;
    max-width: 100%;
}
.CookieWarning p {
    font-size: 0.75rem;
    color: var(--bg);
    margin: 0;
}
.CookieWarning-learnMore, .CookieWarning-learnMore:visited {
   color: var(--yellow2);   
   text-decoration: underline;
   text-decoration-color: var(--subtle-yellow3);
   -webkit-text-decoration-skip: ink;
   text-decoration-skip: ink;
}
.CookieWarning-learnMore:hover {
   color: var(--yellow1);  
}
button.is-link {
    padding: 0;
    outline: none;
    border: none;
    border-radius: 0;
    background: none;
    font-size: 1rem;
    color: #f15d22;
    font-weight: 600;
    text-align: left;
    box-shadow: none;
    cursor: pointer;
    margin: 0;
}
button.CookieWarning-consent {
    font-size: 1rem;
}
@media screen and (max-width: 500px){
   .CookieWarning {
      right: 1rem;
      left: 1rem;
      margin-left: 0;
      width: auto;
   }
}